import "assets/scss/material-kit-react.scss?v=1.8.0";
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import 'assets/css/fa.css';
import "assets/scss/material-kit-react.scss";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import moment from 'moment'

import styles from "assets/jss/material-kit-react/views/index.js";

// Sections for this page
import SEO from "../components/seo";
import profile from "assets/img/faces/duaa_blog.jpg";

import BasicCalendar from '../components/calendar'
const dashboardRoutes = [];

const useStyles = makeStyles(styles);



export default function Appointments(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const events = props.data.allWordpressWpCalendarEntry.nodes.map(node => {
    console.log(node);
    return {start: moment(node.acf.start_date)._d,end: moment(node.acf.end_date)._d,title: node.acf.event_text}
  })
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Duaa Rasho"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 30,
          color: "white"
        }}
        {...rest}
      />
      <SEO image={profile} pathname={"/contact/"}></SEO>
      <Parallax small filter image={require("assets/img/landing-bg.jpg")}>
      <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Appointments</h1>
              <h4>
                 Here are my readings and other appointments on the calendar listed.
              </h4>
              <br />
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div style={{height:"100vh"}}>
          <BasicCalendar events={events}></BasicCalendar>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export const postQuery = graphql`
query GetCalendarEntries {
  allWordpressWpCalendarEntry {
    nodes {
      acf {
        end_date
        event_text
        start_date
      }
    }
  }
}


`
