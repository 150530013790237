import React from "react";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/en-gb';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import Fab from '@material-ui/core/Fab';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const localizer = momentLocalizer(moment)

let allViews = Object.keys(Views).map(k => Views[k])

function Event({ event }) {
    const matches = useMediaQuery('(max-width:1600px)');
    console.log(matches);
    console.log(event);
    return (
        <>
            {matches ?
                <>
                    <PopupState variant="popper" popupId="demo-popup-popper">
                        {popupState => (
                            <div style={{ display: "inline", marginRight: "10px" }}>
                                <Fab style={{ width: "38px", height: "38px" }} aria-label="add" {...bindToggle(popupState)}>
                                    <SearchIcon />
                                </Fab>
                                <Popper style={{ zIndex: "3" }} {...bindPopper(popupState)} transition>
                                    {({ TransitionProps }) => (
                                        <Fade {...TransitionProps} timeout={350}>
                                            <Paper>
                                                <Typography color="textPrimary" style={{ padding: "8px", textAlign: "center" }} >From {moment(event.start).format('DD.MM.YYYY HH:ss')} until {moment(event.end).format('DD.MM.YYYY HH:ss')}
                                                    <br /><strong>{event.title}</strong></Typography>
                                            </Paper>
                                        </Fade>
                                    )}
                                </Popper>
                            </div>
                        )}
                    </PopupState>
                    <Typography color="initial" style={{ display: "inline" }} >
                    <strong>{event.title}</strong></Typography>
                </>
                :
                <Typography color="initial" style={{ textAlign: "center" }} >{moment(event.start).format('DD.MM.YYYY HH:ss')}-{moment(event.end).format('DD.MM.YYYY HH:ss')}
                <br /><strong>{event.title}</strong></Typography>
            }
        </>
    )
}
function EventWeek({ event }) {
    const matches = useMediaQuery('(max-width:790px)');
    console.log(matches);
    console.log(event);
    return (
        <>
            {matches ?
                <PopupState variant="popper" popupId="demo-popup-popper">
                    {popupState => (
                        <div style={{ display: "block", textAlign: "center" }}>
                            <Fab style={{ width: "38px", height: "38px" }} aria-label="add" {...bindToggle(popupState)}>
                                <SearchIcon />
                            </Fab>
                            <Popper style={{ zIndex: "3" }} {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper>
                                            <Typography color="textPrimary" style={{ padding: "8px", textAlign: "center" }} >From {moment(event.start).format('DD.MM.YYYY HH:ss')} until {moment(event.end).format('DD.MM.YYYY HH:ss')}
                                                <br /><strong>{event.title}</strong></Typography>
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    )}
                </PopupState>
                :
                null
            }
            <Typography color="initial" style={{ textAlign: "center" }} ><strong>{event.title}</strong></Typography>
        </>
    )
}
let BasicCalendar = (props) => {

    return (
        <Calendar
            popup
            localizer={localizer}
            views={allViews}
            showMultiDayTimes
            events={props.events}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            components={{
                month: {
                    event: Event // with the agenda view use a different component to render events
                },
                week: {
                    event: EventWeek // with the agenda view use a different component to render events
                },
                work_week:{
                    event: EventWeek
                }

            }}
        />
    )
}

export default BasicCalendar